.mobile-nav {
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h1 {
    text-align: center;
    display: inline-block;
    font-size: 22px;
  }
}

/* mobile button style */
.menu-btn {
  width: 80px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.menu-btn__burger {
  margin-left: 40px;
}

.menu-btn__burger,
.menu-btn__burger::before,
.menu-btn__burger::after {
  width: 35px;
  height: 3px;
  border-radius: 5px;
  background-color: #da96fc;
  box-shadow: 0 2px 5px var(--box-shadow-color);
  transition: all 0.5s ease-in-out;
}
.menu-btn__burger::before,
.menu-btn__burger::after {
  content: "";
  position: absolute;
}
.menu-btn__burger::before {
  transform: translateY(-10px);
}
.menu-btn__burger::after {
  transform: translateY(10px);
}

/* ANIMATION */
.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background-color: transparent;
  box-shadow: none;
}
.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}
