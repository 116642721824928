.menu {
  height: 4rem;
}
.menu ol {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  // background-color: #0c0c0c;
}
.menu > ol {
  max-width: 1000px;
  padding: 0 2rem;
  display: flex;
}
.menu > ol > .menu-item {
  flex: 1;
  padding: 0.75rem 0;
}
.menu > ol > .menu-item:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  bottom: 5px;
  left: calc(50% - 2px);
  /* background: #feceab; */
  will-change: transform;
  transform: scale(0);
  transition: transform 0.2s ease;
}
.menu > ol > .menu-item:hover:after {
  transform: scale(1);
}
.menu-item {
  position: relative;
  line-height: 2.5rem;
  text-align: center;
  a {
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    color: #fff;
    width: 200px;
  }
  a:hover {
    color: #6724fd;
  }
}

.sub-menu .menu-item {
  padding: 0.75rem 0;
  // background: #222222;
  opacity: 0;
  transform-origin: bottom;
  animation: enter 0.2s ease forwards;
}
.sub-menu .menu-item:nth-child(1) {
  animation-duration: 0.2s;
  animation-delay: 0s;
}
.sub-menu .menu-item:nth-child(2) {
  animation-duration: 0.3s;
  animation-delay: 0.1s;
}
.sub-menu .menu-item:nth-child(3) {
  animation-duration: 0.4s;
  animation-delay: 0.2s;
}
.sub-menu .menu-item:nth-child(4) {
  animation-duration: 0.5s;
  animation-delay: 0.3s;
}
.sub-menu .menu-item:hover {
  background-color: #6724fd;
}
.sub-menu .menu-item a {
  padding: 0 0.75rem;
}

.sub-menu {
  a:hover {
    color: #fff;
  }
}

.sub-menu {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  display: none;
  z-index: 1;
}

.menu-item:hover {
  .sub-menu {
    display: block;
  }
  .arrow {
    transition: 0.4s;
    border: solid #6724fd;
    border-width: 0 3px 3px 0;
    margin-bottom: -1px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3.5px;
  margin: 0px 6px;
  margin-bottom: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

@keyframes enter {
  from {
    opacity: 0;
    transform: scaleY(0.98) translateY(10px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
