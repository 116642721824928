@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  /* background-color: antiquewhite; */
  padding: 0px;
  margin: 0px;
  font-family: "Montserrat", sans-serif !important;
  // font-family: "Poppins", sans-serif !important;
  font-optical-sizing: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px !important;
  color: white;
}

a {
  text-decoration: none !important;
}

p {
  color: white;
}

.text-main {
  color: #1fd1f9;
}

.mybtn {
  border-radius: 5px;
  border: 1px solid #51d1f9;
  padding: 10px 20px;
  background-color: #51d1f9;
  color: #fff;
  :hover {
    background-color: #1fd1f9;
    color: #fff;
    transition: 0.3s;
    border-color: #1fd1f9;
  }
}

.myshadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.text-light {
  color: #da96fc;
}

.text-shadow {
  text-shadow: 1px 1px #da96fc;
}

.myblock {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-image: linear-gradient(#da96fc, #2122f7, #1fd1f9);
}
